$font-family-sans-serif: 'Josefin Sans', sans-serif;

$font-size: 14;
$headings-font-weight: 500;

$brand-primary:#333;
$gray-base:              #000;
$gray-darker:            lighten($gray-base, 13.5%); // #222
$gray-dark:              lighten($gray-base, 20%);   // #333
$gray:                   lighten($gray-base, 33.5%); // #555
$gray-light:             lighten($gray-base, 46.7%); // #777
$gray-lighter:           lighten($gray-base, 93.5%); // #eee

$input-border-primary: mix( #fff, $brand-primary, 50 );

$divider-color: darken( $gray-lighter, 10 );

$screen-xxs-min: 320px;
$screen-xxs-max: 319px;

/**
==========
Hamburgers
==========
 */
$hamburger-layer-width                     : 30px;
$hamburger-layer-height                    : 3px;
$hamburger-layer-spacing                   : 5px;
$hamburger-layer-color                     : $brand-primary;
$hamburger-layer-border-radius             : 3px;
$hamburger-height: ( $hamburger-layer-height * 3 ) + ( $hamburger-layer-spacing * 2 );
$hamburger-types: (slider);

$hamburger-padding-x: 15px;
$hamburger-padding-y: 14px;

/**
===========
Lightcase
===========
 */
$lightcase-custom: (
        'overlay-color': #000,
        'case-background-media': #000,
        'text-color-viewport-large': #000
)
