
@import"~bootstrap/dist/css/bootstrap";
@import "~font-awesome/css/font-awesome";

// @import "~swiper/dist/css/swiper";

@import "~magnific-popup/dist/magnific-popup";

@import "~lightcase/src/scss/lightcase";

@import "fonts";




@import "variables";

@import "utilities";

@import "components";
