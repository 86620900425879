@font-face {
  src: url( '../fonts/Roboto/Roboto-Regular.ttf' );
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
}

@font-face {
  src: url( '../fonts/Roboto/Roboto-Bold.ttf' );
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
}

@font-face {
  src: url( '../fonts/Roboto/Roboto-Italic.ttf' );
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
}


@font-face {
  src: url( '../fonts/Roboto/Roboto-Medium.ttf' );
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
}

@font-face {
  src: url( '../fonts/Roboto/Roboto-MediumItalic.ttf' );
  font-family: 'Roboto';
  font-style: italic;
  font-weight:500;
}

// Roboto Condensed
@font-face {
  src: url( '../fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf' );
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
}

@font-face {
  src: url( '../fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf' );
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
}

@font-face {
  src: url( '../fonts/Roboto_Condensed/RobotoCondensed-Italic.ttf' );
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
}


